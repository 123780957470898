'use client'
import NextAdapterApp from 'next-query-params/app'
import { QueryParamProvider as UseQueryParamProvider } from 'use-query-params'

export const QueryParamProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return (
    <UseQueryParamProvider adapter={NextAdapterApp}>
      {children}
    </UseQueryParamProvider>
  )
}
