'use client'
import ChangePassword from '@/components/Auth/ChangePasswordForm/ChangePassword'

const ChangePasswordForm = ({ data }: { data: any }) => {
  return (
    <>
      <h3 className="mb-10 pt-20 text-h3 font-bold md:pt-10 md:text-center">
        Change password
      </h3>
      <div className="grid gap-2.5">
        <div className="grid gap-10 rounded-md bg-white">
          <ChangePassword data={data}></ChangePassword>
        </div>
      </div>
    </>
  )
}

export default ChangePasswordForm
