'use client'
import * as React from 'react'
import { Box } from '@/lib/mui'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { ArrowOutward } from '@/lib/mui'
import { useRouter } from 'next/navigation'
import { signIn } from 'next-auth/react'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { PASSWORD_REGEX } from '@/constants'
import { FormInputPassword } from '@/components/FormComponents/FormInputPassword'
import { analitycs } from '@/hooks/useAnalyticsInit'
import { AnalitycsEvents } from '@/types/analyticsTypes'
import LoadingBtn from '@/components/LoadingButton'

interface IFormInput {
  confirmPassword: string
  newPassword: string
  password: string
}
const schema = yup.object().shape({
  password: yup.string().required().label('Current Password'),
  newPassword: yup
    .string()
    .matches(
      PASSWORD_REGEX,
      'Password must contain minimum 8 characters, at least one special character, numbers, upercase and lowercase letters',
    )
    .required()
    .label('New Password'),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref('newPassword')], 'Passwords must match')
    .label('Confirm Password'),
})
const ChangePassword = ({ data }: { data: any }) => {
  const [isLoading, setIsLoading] = useState(false)
  const router = useRouter()
  const defaultValues = {
    password: '',
    newPassword: '',
    confirmPassword: '',
  }
  const { control, handleSubmit: accountInforHandle } = useForm({
    defaultValues: defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  const handleAccountInformationFormSubmit = async (formData: IFormInput) => {
    setIsLoading(true)
    const signInResult = await signIn('credentials', {
      email: data.email,
      password: data.password,
      newPass: formData.newPassword,
      redirect: false,
    })
    if (signInResult?.error) {
      setIsLoading(false)
    } else {
      analitycs.track(AnalitycsEvents.firstSignIn)
      sessionStorage.setItem('firstSignIn', 'true')
      window.location.href = '/ethical-oath'
    }
  }

  return (
    <Box
      className="grid max-w-md gap-4 md:max-w-full"
      component="form"
      onSubmit={accountInforHandle(handleAccountInformationFormSubmit)}
      noValidate
    >
      <FormInputPassword
        name="password"
        control={control}
        label="Current password"
        data-cy="sign-in-password"
        color="secondary"
        placeholder="Enter your current password..."
      />
      <FormInputPassword
        name="newPassword"
        control={control}
        label="New password"
        data-cy="sign-in-password"
        color="secondary"
        placeholder="Enter new password..."
      />
      <FormInputPassword
        name="confirmPassword"
        control={control}
        label="Confirm new password"
        data-cy="sign-in-password"
        color="secondary"
        placeholder="Confirm new password..."
      />
      <LoadingBtn
        className="w-full justify-self-start"
        color="primary"
        size="large"
        data-cy="get-access-info-button"
        loading={isLoading}
        icon={<ArrowOutward />}
      >
        Set new password
      </LoadingBtn>
    </Box>
  )
}

export default ChangePassword
