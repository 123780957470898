'use client'
import { Box } from '@/lib/mui'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { ArrowOutward } from '@/lib/mui'
import { FormInputText } from '@/components/FormComponents/FormInputText'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { AuthMessages } from '@/lib/utils'
import LoadingIconSrc from '@public/assets/icons/loading.svg'
import Image from 'next/image'
import { API_PATHS } from '@/constants'
import { analitycs } from '@/hooks/useAnalyticsInit'
import { AnalitycsEvents } from '@/types/analyticsTypes'
import Link from 'next/link'
import { signIn } from 'next-auth/react'
import LoadingBtn from '@/components/LoadingButton'

interface IFormInput {
  code: string
}

const schema = yup.object().shape({
  code: yup.string().required().label('confirmation code'),
})
const defaultValues = {
  code: '',
}

const LoginConfirmForm = ({
  data: { email, password },
}: {
  data: { email: string | null; password: string | null }
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingResendCode, setIsLoadingResendCode] = useState(false)
  const [genericError, setGenericError] = useState<null | string>(null)
  const { control, handleSubmit } = useForm<IFormInput>({
    defaultValues: defaultValues,
    mode: 'onChange',
    resolver: yupResolver(schema),
  })
  const handleConfirmSubmitCallback = async (formData: IFormInput) => {
    setIsLoading(true)
    const response = await fetch(API_PATHS.confirmUser, {
      method: 'POST',
      body: JSON.stringify({
        confirmationCode: formData.code,
        username: email,
      }),
    })
    const result = await response.json()
    if (!result?.error) {
      setIsLoading(false)
      if (
        result.response.isSignUpComplete &&
        result.response.nextStep === AuthMessages.done
      ) {
        const signInResult = await signIn('credentials', {
          email: email,
          password: password,
          isConfirm: true,
          redirect: false,
        })
        if (signInResult?.error) {
          setIsLoading(false)
        } else {
          analitycs.track(AnalitycsEvents.firstSignIn)
          sessionStorage.setItem('firstSignIn', 'true')
          window.location.href = '/ethical-oath'
        }
      }
    } else {
      setIsLoading(false)
      setGenericError(result?.message)
    }
  }

  const resendCodeCallback = async () => {
    setIsLoadingResendCode(true)
    const response = await fetch(API_PATHS.resendCode, {
      method: 'POST',
      body: JSON.stringify({
        username: email,
      }),
    })
    const result = await response.json()
    if (!result?.error) {
      setIsLoadingResendCode(false)
    }
  }

  return (
    <>
      <h3 className="pt-36 text-h3 font-bold">Welcome back!</h3>
      <p className="mb-10 text-md leading-base text-dark-grey">
        It looks like you haven&apos;t verified your account yet. Please check
        your email for the verification code we sent you, and enter it here to
        complete your registration and start using your account.
      </p>
      <div className="grid gap-2.5">
        <div className="grid gap-10 rounded-md bg-white">
          <Box
            className="grid max-w-md gap-4 md:max-w-full"
            component="form"
            onSubmit={handleSubmit(handleConfirmSubmitCallback)}
            noValidate
          >
            {genericError ? (
              <p className="text-sm font-medium text-red-error">
                {genericError}
              </p>
            ) : (
              ''
            )}
            <FormInputText
              name="code"
              control={control}
              label="Verification code"
              data-cy="confirm-code"
              color="secondary"
              placeholder="Enter verification code..."
            />
            <LoadingBtn
              className="w-full justify-self-start"
              color="primary"
              size="large"
              data-cy="get-access-info-button"
              loading={isLoading}
              icon={<ArrowOutward />}
            >
              Submit Code
            </LoadingBtn>
            <div className="mt-4 flex justify-center">
              <p className="text-dark pr-1 text-md leading-base">
                Didn&apos;t get a code?
              </p>
              {isLoadingResendCode ? (
                <Image
                  src={LoadingIconSrc}
                  alt="loading"
                  className="animate-spin"
                />
              ) : (
                <Link
                  className="anchor"
                  href="/auth/register"
                  onClick={(evt) => {
                    evt.preventDefault()
                    resendCodeCallback()
                  }}
                >
                  Resend code
                </Link>
              )}
            </div>
          </Box>
        </div>
      </div>
    </>
  )
}

export default LoginConfirmForm
